<template>
  <div class="wraper pagezclist" style="width: 100%;height: 100%;">
      <el-row style="margin-bottom: 10px;" class="">
        <div class="">
          <el-input v-model="searchForm.mohuTitle" clearable style="width: 300px!important;"
                    placeholder="模糊搜索">
            <el-button slot="append" icon="el-icon-search" @click="onSearch"></el-button>
          </el-input>
        </div>
      </el-row>

      <el-table :data="DataList" height="calc( 100% - 100px)"
                style="width: 100%;" stripe border>
        <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
        <el-table-column prop="sp_status" label="状态" width="85" :show-overflow-tooltip="true" align="center">
          <template slot-scope="scope">

            <el-tag size="mini" type="primary" v-if="scope.row.sp_status == 0">待审核</el-tag>

            <el-tag size="mini" type="warning" v-if="scope.row.sp_status==1&&scope.row.ck_status==0">待出库</el-tag>
            <el-tag size="mini" type="success" v-if="scope.row.sp_status==1&&scope.row.ck_status==1" style="margin-right: 6px;">已出库</el-tag>

            <el-tag size="mini" type="warning" v-if="scope.row.sp_status==1&&scope.row.ck_status==1&&(!scope.row.qs_json||!scope.row.qs_json.qs_userid)">待签收</el-tag>
            <el-tag size="mini" type="success" v-if="scope.row.sp_status==1&&scope.row.ck_status==1&&(scope.row.qs_json&&scope.row.qs_json.qs_userid)">已签收</el-tag>

            <el-tag size="mini" type="danger" v-if="scope.row.sp_status == 2">审核驳回</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="名称" min-width="200" align="center">
          <template slot-scope="scope">
            <div style="color: #409EFF;cursor: pointer;">{{ scope.row.title }}</div>
          </template>
        </el-table-column>
        <!-- <el-table-column v-if="false" prop="typename" label="分类" width="120" align="center">
          <template slot-scope="scope">
            {{ scope.row.typename2 }} {{ scope.row.typename1 }}
          </template>
        </el-table-column> -->
        <el-table-column prop="pinpai" label="品牌" min-width="85" align="center"/>
        <el-table-column prop="xinghao" label="型号" min-width="85" :show-overflow-tooltip="true" align="center"/>
        <!-- <el-table-column prop="jldw" label="计量单位" width="85" align="center"/> -->

        <!-- <el-table-column v-if="false" prop="jine" label="单价（加权平均）" width="130" :show-overflow-tooltip="true">
          <template slot="header">
            <div style="text-align: center;">单价</div>
            <div style="text-align: center;">（加权平均值）</div>
          </template>
          <template slot-scope="scope">
            <div class="flexCenter">￥{{ scope.row.jine }}</div>
          </template>
        </el-table-column> -->
        <el-table-column prop="storename" label="所在仓库" min-width="120" align="center"/>
        <el-table-column prop="slNum" label="申领数量" width="85" align="center"/>

        <el-table-column prop="sl_remark" label="申领原因" :show-overflow-tooltip="true" min-width="120" align="center"/>
      </el-table>
      <el-pagination class="cus_page"
                     @size-change="handleSizeChange"
                     @current-change="paginate"
                     :current-page.sync="page.current_page"
                     :page-sizes="[10, 50, 100]"
                     :page-size="page.per_page"
                     layout="total,sizes, prev, pager, next"
                     :total="page.count">
      </el-pagination>


  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      searchForm: {},
      DataList: [],
      multipleSelection: [],
      page: {
        current_page: 1,
        per_page: 50,
        count: 0,
        pageSizes: [10, 50, 100]
      },
    }
  },
  mounted() {
    this.getHcList();
  },
  methods: {
    getHcList(){
      let _this = this
      _this.DataList = []
      let params = {}, arr = []
      params.mohuTitle = _this.searchForm.mohuTitle
      params.page = _this.page.current_page ? _this.page.current_page : 1
      params.pagesize = _this.page.per_page ? _this.page.per_page : 50
      params.yujing = 1
      params.yujing = 'myself'
      this.$http.post("/api/zc_hc_sl_list", params).then(res => {
        for(let item of res.data.data){
          if(item.qs_json){
            item.qs_json = JSON.parse(item.qs_json)
          }
          if(item.hc_list){
            let arr1 = JSON.parse(item.hc_list)
            arr1&&arr1.length>0&&arr1.map(a=>{
              let obj = JSON.parse(JSON.stringify(item))
              if(a.jine)a.jine = parseFloat(a.jine).toFixed(2)
              obj.hcInfo = a
              arr.push(obj)
            })
          }
          this.DataList = arr;
        }
        if (res.data && res.data.page) {
          this.page = res.data.page
          this.page_hc = res.data.page
        }
        console.log(arr)
      })
    },

    onSearch() {
      this.page.current_page = 1
      this.getHcList()
    },

    handleSizeChange(val) {
      this.page.current_page = 1
      this.page.per_page = val
      this.getHcList();
    },
    paginate(val) {
      this.page.current_page = val
      this.getHcList();
    },
  }
}
</script>

<style lang="less" type="text/less">

.pagezclist {
  box-shadow: 0 0 5px #409eff81;
  padding-left: 10px;
  padding-bottom: 10px;
  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
    font-size: 12px;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .labelCss {
    .el-form-item__label {
      color: #909399 !important;
      font-size: 12px;
    }
  }

  .el-input, .el-select, .el-cascader {
    width: 100% !important;
  }

  .el-dropdown {
    vertical-align: top;
  }

  .el-dropdown + .el-dropdown {
    margin-left: 15px;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .cus_dropdown {
    .el-button--primary {
      color: #409EFF !important;
      background-color: #Ecf5ff !important;
      border-color: #b3d8ff !important;
    }
  }

  .el-divider {
    margin-top: 0;
  }

  .el-drawer__wrapper {
    top: 70px;
  }

  .drawer_box, .targetUsers {
    padding: 10px;

    .el-form-item {
      margin-bottom: 0px;
    }
  }

  .updimage {
    width: 100px;
    height: 100px;
    display: inline-block;
    vertical-align: top;
    margin: 0 10px 10px 0;
    border: 1px dotted #ccc;
    background-size: 100% auto;
    background-position: center;
    overflow: hidden;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .cus_page {
    margin-top: 10px;

    .el-pagination__sizes {
      width: 130px;
    }
  }
}
</style>